import gql from 'graphql-tag'

export const productDynamic = gql`
query product($slug: String!) {
  productdynamics(filters:{ slug: { eq: $slug } }) {
    data {
      id
      attributes {
        metatitle
        metadescription
        page {
          __typename
          ...on ComponentBlocksTekstovoePole {
            id
            text
          }
          ...on ComponentBlocksOblozhkaStraniczy {
            id
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
            link
            linkText
          }
          ...on ComponentBlocksBlokOProdukte {
            id
            header
            description
            url
            iconcard {
              data {
                id
                attributes {
                  header
                  description
                  icon {
                    data {
                      attributes {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksBlokObIndustrii {
            id
            header
            description
            subheader
            achievements {
              data {
                attributes {
                  title
                  description
                }
              }
            }
          }
          ...on ComponentBlocksBlokKomuPodojdet {
            id
            header
            description
            iconcards {
              data {
                attributes {
                  header
                  description
                  icon {
                    data {
                      id
                      attributes {
                        name
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          ...on ComponentBlocksDinaBlokPreimushhestva {
            id
            header
            tileset {
              data {
                id
                attributes {
                  type
                  cards {
                    data {
                      id
                      attributes {
                        header
                        description
                        link
                        icon {
                          data {
                            id
                            attributes {
                              image {
                                data {
                                  id
                                  attributes {
                                    url
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksBlokNamDoveryayut {
            id
            title
            description
            logos {
              data {
                id
                attributes {
                  name
                  image {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  imagecolor {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksBlokKontakty {
            contactscentral {
              data {
                id
                attributes {
                  title
                  companyname
                  address
                  centralcoords
                  phone
                  email
                  yandexbiz
                  hrtitle
                  hrdescription
                  hrphone
                  hremail
                }
              }
            }
            othercontacts(pagination: { limit: 1000 }) {
              data {
                id
                attributes {
                  city
                  address
                  yandexbiz
                  citycoords
                  officecoords
                }
              }
            }
          }
          ...on ComponentBlocksBlokKontaktyDlyaSmi {
            id
            title
            description
            phone
            email
            file {
              data {
                attributes {
                  url
                  size
                  name
                  mime
                }
              }
            }
            btntext
            cards {
              data {
                id
                attributes {
                  header
                  description
                  icon {
                    data {
                      attributes {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          ...on ComponentBlocksBlokOKompaniiSIkonkami {
            id
            title
            description
            cards {
              data {
                id
                attributes {
                  header
                  description
                  icon {
                    data {
                      id
                      attributes {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksTabySDokumentami {
            id
            title
            filetabs {
              data {
                id
                attributes {
                  frontname
                  files {
                    ...on ComponentBlocksFajl {
                      id
                      filename
                      file {
                        data {
                          attributes {
                            url
                            mime
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentMainpageNovosti {
            id
            title
            link_more_caption
            link_more_url
            news {
              data {
                attributes {
                  slug
                  title
                  published
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksBlokProduktyIUslugi {
            id
            title
            description
            cards(pagination: { limit: 1000 }) {
              data {
                id
                attributes {
                  title
                  link
                  image {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksRazdelitelBlokov {
            id
            paddingtop
            paddingbottom
            fullwidth
            visible
          }
          ...on ComponentBlocksFormaProdukta {
            id
            title
            product {
              data {
                attributes {
                  clientName
                  systemId
                }
              }
            }
            formFields {
              data {
                attributes {
                  type
                  label
                  errorMessage
                }
              }
            }
            legal {
              data {
                attributes {
                  legalText
                }
              }
            }
          }
          ...on ComponentBlocksFormaOsnovnaya {
            id
            tipy_forms {
              data {
                id
                attributes {
                  reason
                  formSubtitle
                  formFields {
                    data {
                      attributes {
                        type
                        label
                        errorMessage
                        selectList {
                          data {
                            attributes {
                              clientName
                              systemId
                            }
                          }
                        }
                      }
                    }
                  }
                  siteFormTitle
                  url
                  description
                  linkTitle
                  legal {
                    data {
                      attributes {
                        legalText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`